<template>
  <div class="grid">
    <div class="flex col-12 justify-content-center" v-if="loading" style="position: absolute;top: 50%;transform: translate(0, -50%);">
      <ProgressSpinner></ProgressSpinner>
    </div>
    <div class="col-12 lg:col-4" v-show="!loading">
      <Card>
        <template #title>Поиск займа</template>
        <template #content>
          <AbstractForm
            ref="form"
            :loading="formLoading"
            v-model="searchForm"
            :on-commit="searchLoan"
            :after-clear="() => {this.loan = null; this.borrower = null;}"
            commit-label="Найти"
            commit-icon="pi pi-search"
          ></AbstractForm>
        </template>
      </Card>
    </div>
    <div class="col-12 lg:col-8" v-if="loan !== null && !loading">
      <Card>
        <template #title>Займ найден</template>
        <template #content>
          <div class="grid">
            <div class="col-12 lg:col-6">
              <Loan :loan="loan"/>
            </div>
            <div class="col-12 lg:col-6">
              <Borrower :borrower="borrower"/>
            </div>
          </div>
        </template>
        <template #footer>
          <Message severity="warn" :closable="false" v-if="loan.status.code !== 'closed'">Нельзя создать событие закрытия пока займ активен.</Message>
          <Message severity="warn" :closable="false" v-if="loan.hasEvents.includes('close_cession') === true">Нельзя создать событие закрытия займа - займ закрыт по цессии (продан).</Message>

          <SplitButton v-if="loan !== null && borrower !== null" label="Действия" icon="pi pi-bolt" :model="generateItems({loan, borrower})"></SplitButton>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm.vue";
import FormModel from "@/model/FormModel";
import Loan from "@/components/Event/Loan";
import FilterDateService from "@/service/FilterDateService";
import ResponseService from "@/service/ResponseService";
import LoanService from "@/service/LoanService";
import Borrower from "@/components/Event/Borrower.vue";

export default {
  name: "LoanSearchForm",
  components: {Borrower, AbstractForm, Loan },
  props: {
    actions: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      searchForm: {
        id: new FormModel('ID займа')
      },
      loan: null,
      borrower: null
    }
  },
  methods: {
    generateItems(data) {
      return this.actions(data);
    },
    formatDate(date) {
      return FilterDateService.dateToString(date)
    },
    searchLoan() {
      let id = this.searchForm.id.value
      if (typeof id === 'string') {
        id = this.searchForm.id.value.trim()
      }

      this.formLoading = true
      LoanService.getLoan(id).then((res) => {
        if (res.error === true) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Ошибка поиска займа',
            detail: res.message,
            life: 5000
          })
        }
        this.loan = res.loan
        this.borrower = res.borrower
      })
      .catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.searchForm, this.$toast)
      })
      .finally(() => {
        this.formLoading = false
      })
    },
    setLoading(value) {
      this.loading = value
    },
    clear() {
      this.$refs.form.clear();
    }
  }
}
</script>