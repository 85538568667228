<template>
  <div>
    <Card>
      <template #title>Заёмщик</template>
      <template #content>
        <p><strong>ID: </strong>{{ borrower.id }}</p>
        <p><strong>UUID: </strong>{{ borrower.uuid }}</p>
        <Divider></Divider>
        <p><strong>ФИО: </strong>{{ borrower.surname }} {{ borrower.name }} {{ borrower.patronymic }}</p>
        <p><strong>Дата рождения: </strong>{{ borrower.birthday }}</p>
        <Divider></Divider>
        <p><strong>Телефон: </strong>{{ borrower.phone }}</p>
        <p v-if="borrower.email !== null"><strong>E-mail: </strong>{{ borrower.email }}</p>
        <Divider></Divider>
        <p><strong>Серия паспорта: </strong>{{ borrower.passport.series }}</p>
        <p><strong>Номер паспорта: </strong>{{ borrower.passport.number }}</p>
        <p><strong>Дата выдачи паспорта: </strong>{{ borrower.passport.issueDate }}</p>
        <p><strong>Место выдачи паспорта: </strong>{{ borrower.passport.issuePlace }}</p>
        <p><strong>Код подразделения: </strong>{{ borrower.passport.issuer }}</p>
        <Divider></Divider>
        <Panel header="Адреса" :toggleable="true" :collapsed="true">
          <TabView>
            <TabPanel header="Адрес регистрации">
              <BorrowerAddress :address="borrower.realAddress"></BorrowerAddress>
            </TabPanel>
            <TabPanel header="Фактический адрес">
              <BorrowerAddress :address="borrower.registrationAddress"></BorrowerAddress>
            </TabPanel>
          </TabView>
        </Panel>
      </template>
    </Card>
  </div>
</template>

<script>
import BorrowerAddress from "@/components/Event/BorrowerAddress";
export default {
  name: "Borrower",
  components: {BorrowerAddress},
  props: {
    borrower: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>