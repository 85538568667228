<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Закрытие займа</template>
        <template #content>
          <LoanSearchForm
            ref="searchForm"
            :actions="createActions"
          />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import LoanSearchForm from "@/components/Forms/LoanSearchForm.vue";
import CloseLoanActions from "@/helpers/actions/closeLoanActions";

export default {
  name: "CloseLoan",
  components: { LoanSearchForm },
  methods: {
    createActions(data) {
      const actions = new CloseLoanActions(data);

      actions.beforeClose = () => {
        this.$refs.searchForm.setLoading(true);
      }
      actions.afterClose = () => {
        this.$refs.searchForm.setLoading(false);
        this.$refs.searchForm.clear();
      }

      return actions.getItems();
    }
  }
}
</script>