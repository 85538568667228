<template>
  <div>
    <p v-if="address.postalCode !== '' && address.postalCode !== null"><strong>Почтовый индекс: </strong>{{ address.postalCode }}</p>
    <p v-if="address.country !== '' && address.country !== null"><strong>Страна: </strong>{{ address.country }}</p>
    <p v-if="address.regionFiasId !== '' && address.regionFiasId !== null"><strong>Код ФИАС региона: </strong>{{ address.regionFiasId }}</p>
    <p v-if="address.region !== '' && address.region !== null"><strong>Регион: </strong>{{ address.region }}</p>
    <p v-if="address.areaFiasId !== '' && address.areaFiasId !== null"><strong>Код ФИАС района в регионе: </strong>{{ address.areaFiasId }}</p>
    <p v-if="address.area !== '' && address.area !== null"><strong>Район в регионе: </strong>{{ address.area }}</p>
    <p v-if="address.cityFiasId !== '' && address.cityFiasId !== null"><strong>Код ФИАС города: </strong>{{ address.cityFiasId }}</p>
    <p v-if="address.city !== '' && address.city !== null"><strong>Город: </strong>{{ address.city }}</p>
    <p v-if="address.cityDistrictFiasId !== '' && address.cityDistrictFiasId !== null"><strong>Код ФИАС района города: </strong>{{ address.cityDistrictFiasId }}</p>
    <p v-if="address.cityDistrict !== '' && address.cityDistrict !== null"><strong>Район города: </strong>{{ address.cityDistrict }}</p>
    <p v-if="address.settlementFiasId !== '' && address.settlementFiasId !== null"><strong>Код ФИАС населённого пункта: </strong>{{ address.settlementFiasId }}</p>
    <p v-if="address.settlement !== '' && address.settlement !== null"><strong>Населённый пункт: </strong>{{ address.settlement }}</p>
    <p v-if="address.streetFiasId !== '' && address.streetFiasId !== null"><strong>Код ФИАС улицы: </strong>{{ address.streetFiasId }}</p>
    <p v-if="address.street !== '' && address.street !== null"><strong>Улица: </strong>{{ address.street }}</p>
    <p v-if="address.houseFiasId !== '' && address.houseFiasId !== null"><strong>Код ФИАС дома: </strong>{{ address.houseFiasId }}</p>
    <p v-if="address.house !== '' && address.house !== null"><strong>Дом: </strong>{{ address.house }}</p>
    <p v-if="address.block !== '' && address.block !== null"><strong>Корпус: </strong>{{ address.block }}</p>
    <p v-if="address.flat !== '' && address.flat !== null"><strong>Кваритра: </strong>{{ address.flat }}</p>
  </div>
</template>

<script>
export default {
  name: "BorrowerAddress",
  props: {
    address: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>