<template>
  <div>
    <Card>
      <template #title>Займ</template>
      <template #content>
        <p><strong>ID: </strong>{{ loan.id }}</p>
        <p><strong>UUID: </strong>{{ loan.uuid }}</p>
        <Divider></Divider>
        <p><strong>Сумма: </strong>{{ $filters.currency(loan.amount) }}</p>
        <p><strong>Срок: </strong>{{ loan.term }}</p>
        <p v-if="loan.status"><strong>Статус: </strong>{{ loan.status.name }}</p>
        <p v-if="loan.model"><strong>Модель: </strong>{{ loan.model.name }} ({{ loan.model.code }})</p>
        <Divider></Divider>
        <p><strong>Дата выдачи: </strong>{{ loan.issuedAt }}</p>
        <p v-if="loan.plannedEnd !== null"><strong>Планируемая дата закрытия: </strong>{{ loan.plannedEnd }}</p>
        <p v-if="loan.factEnd !== null"><strong>Фактическая дата закрытия: </strong>{{ loan.factEnd }}</p>
        <Divider></Divider>

        <Panel header="Балансы" :toggleable="true" :collapsed="true">
          <DataTable :value="loan.accounts" stripedRows>
            <Column field="name" header="Имя" style="max-width: 100px;"></Column>
            <Column field="balance" header="Баланс" style="max-width: 200px;">
              <template #body="{data}">
                {{ $filters.currency(data.balance) }}
              </template>
            </Column>
          </DataTable>
        </Panel>

        <Panel v-if="loan.cession !== null" header="Продажа по цессии" :toggleable="true" :collapsed="true" style="margin-top: 20px">
          <p><strong>Имя организации: </strong>{{ loan.cession.data.organisation.shortName }}</p>
          <p><strong>Полное имя организации: </strong>{{ loan.cession.data.organisation.fullName }}</p>
          <p><strong>ОГРН: </strong>{{ loan.cession.data.organisation.ogrn }}</p>
          <p><strong>ОКПО: </strong>{{ loan.cession.data.organisation.okpo }}</p>
          <p><strong>СНИЛС: </strong>{{ loan.cession.data.organisation.snils }}</p>
          <p><strong>Это коллекторы: </strong>{{ $filters.boolean(loan.cession.data.organisation.isCollector) }}</p>
          <p><strong>Дата продажи по цессии: </strong>{{ formatDate(loan.cession.sell_at) }}</p>
        </Panel>
      </template>
    </Card>
  </div>
</template>

<script>
import FilterDateService from "@/service/FilterDateService";

export default {
  name: "Loan",
  props: {
    loan: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate(date) {
      return FilterDateService.dateToString(date)
    },
  }
}
</script>

<style scoped>

</style>