import app from "@/main";
import LoanService from "@/service/LoanService";
import {showErrorToast, showToast} from "@/helpers/actions/helpers";

class CloseLoanActions {
    confirm = app.config.globalProperties.$confirm;

    items = [];

    beforeClose = null;
    afterClose = null;

    constructor({ loan }) {
        this.items = [
            {
                label: 'Закрыть займ',
                icon: 'pi pi-times-circle',
                disabled: loan.status.code !== 'closed' || loan.hasEvents.includes('close_cession'),
                command: () => this.close(loan)
            }
        ];
    }

    close({ id, hasEvents }) {
        let message = `Будет создано событие для закрытия займа №${id}`;
        if (hasEvents.includes('close')) {
            message = `Для займа №${id} уже есть одно или более событий о его закрытии. Создать новое событие для закрытия займа?`;
        }

        this.confirm.require({
            message: message,
            header: 'Подтвердите закрытие займа',
            acceptLabel: 'Закрыть займ',
            acceptIcon: 'pi pi-times-circle',
            rejectLabel: 'Отмена',
            rejectIcon: 'pi pi-ban',
            accept: () => {
                if (this.beforeClose !== null) {
                    this.beforeClose();
                }

                LoanService.closeLoan(id).then((res) => {
                    showToast(res.feedback, res.error);
                })
                .catch((err) => {
                    showErrorToast(err);
                })
                .finally(() => {
                    if (this.afterClose !== null) {
                        this.afterClose();
                    }
                })
            }
        });
    }

    getItems() {
        return this.items;
    }
}

export default CloseLoanActions;